import React from 'react'
import ProjectDetail from './components/portfolio/ProjectDetail'
import Main from './components/Main'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  return (
<>
<h1 align='center' font-size="60">Under Maintenance</h1>
</>
    // <>
    // <Router>
    //   <Routes>
    //   <Route path="/" element={<Main />}/>
    //   <Route path="/project/:id" element={<ProjectDetail/>}/>
    //   </Routes>
    // </Router>
    // </>
    
  )
}
export default App